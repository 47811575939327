import React from 'react';
import { connect } from 'react-redux';
import { isIOS, isMobile } from 'mobile-device-detect';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import safeAreaInsets from 'safe-area-insets';
import { UIStore } from '../../stores';
import { updateAuth as actionUpdateAuth } from '../../actions';
import { signOutUser } from '../../pages/Auth/actions';
import './style.scss';
import Submenu from './submenu';

const isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true;

const env = process.env.NODE_ENV || 'development';
export const config = require('../../../config/config.json')[env];

// FIXME This is slow to open the first time - see if it can be optimized to open more quickly - we don't use useEffect at all
function PageDrawer({ settings }) {
  const showNavDrawer = UIStore.useState((s) => s.showMenu);

  const toggleSidebar = () => {
    UIStore.update((s) => {
      s.showMenu = !s.showMenu;
    });
  };

  // Handles the status bar or notch
  const insetTop = safeAreaInsets.top;

  return (
    <>
      {isMobile ? (
        <SwipeableDrawer
          open={showNavDrawer}
          onClick={toggleSidebar}
          onKeyDown={toggleSidebar}
          onRequestChange={(open) => toggleSidebar(open)}
          transitionDuration={250}
        >
          <div style={{ width: 265, marginLeft: 20, marginRight: 20, marginBottom: 30, marginTop: (window.Capacitor.getPlatform() === 'ios' || (isIOS && isInStandaloneMode)) ? insetTop : 0 }}>
            <Submenu navigation={settings.navigation_sidebar} />
          </div>
        </SwipeableDrawer>
      ) : (
        <div className="yaybar rui-yaybar yay-hide-to-small yay-gestures">
          <div className="yay-wrap-menu">
            <div className="yaybar-wrap">
              <ul style={{ height: 'calc(100vh - 160px)' }}>
                <Submenu navigation={settings.navigation_sidebar} />
              </ul>
            </div>
          </div>
          <div className="rui-yaybar-icons" style={{ paddingTop: 20, marginBottom: 20, position: 'absolute', bottom: 0 }}>
            <div className="bottom-links">
              <a href="https://boxpressd.com/legal/privacy">Privacy Policy</a>
              {' · '}
              <a href="https://boxpressd.com/legal/terms">Terms of Service</a>
              {' · '}
              <a href="https://boxpressd.com/ads">Advertising</a>
            </div>
            <span>
              Boxpressd Ltd &copy;
              {' '}
              {new Date().getFullYear()}
            </span>
          </div>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
    settings: state.get('settings'),
  };
}

const mapDispatchToProps = (dispatch) => ({
  signOutUser: (callback) => dispatch(signOutUser(callback)),
  updateAuth: actionUpdateAuth,
});

export default connect(mapStateToProps, mapDispatchToProps)(PageDrawer);
